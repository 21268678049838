<template>
  <div class="searchWrapper">
    <!-- <el-button class="right"
              style="width: 88px; height: 36px; margin-top: -46px;"
              @click="route">返回
    </el-button> -->
    <!--列表区域-->
    <div class="tableWrapper">
      <el-table
        border
        
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        height="250"
      >
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <div class="pagerWrapper" style="float: right">
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDuring } from "@/common/js/public";
export default {
  props: {
    carId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      pageSize: 15,
      pageNum: 1,
      total: 0,
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
          // formatter: (row) => {
          //   return formatDuring(row.parkTime)
          //   // if (row.parkTime) {

          //   //    return row.parkTime + '分钟'
          //   // } else {
          //   //    return '0' + '分钟'
          //   // }
          // }
        },
        {
          prop: "shouldPay",
          label: this.$t("list.arrears"),
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
    };
  },
  methods: {
    route() {
      this.$router.push("/arrearagePlateList");
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData(this.$route.query.data);
    },
    getData() {
      // /acb/2.0/debtStatistics/arrearageplatedetail 老接口

      this.$axios
        .get("/acb/2.0/debtOrder/list", {
          data: {
            entryExitType: 2,
            carId: this.carId,
            page: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        })
        .catch((err) => {
          this.total = 0;
          this.tableData = [];
          // console.log(err)
        });
    },
  },
  watch: {
    carId: {
      handler() {
        this.pageNum = 1;
        this.pageSize = 15;
        this.getData()
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper {
 
}
</style>
