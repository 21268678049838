<template>
  <div>
      <graphTitle :title="'欠费金额趋势'">
        <el-tooltip placement="top" content="展示近12个月不同计费类型区域欠费金额对比情况">
          <i class="el-icon-question my-icon" />
        </el-tooltip>
        <!-- <span style="margin-left:10px">最近统计时刻: {{lastCountTime}}</span> -->
      </graphTitle>
    <div id="circularChart"
        class="circularChart">
    </div>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {}
      }
    },
    arrearageSummationtableData: {
      //   type: Array,
      default: function () {
        return {}
      }
    },
    type: {
      default: 'c1'
    },
     lastCountTime: {
      default: ''
    }
  },
  data () {
    return {
      totalNum: 0,
      // 日期
      dateList: [],
      nameList: [],
      dataList: [],
      // 全部
      series: [],
      // 一类区
      series1: [],
      // 二类区
      series2: [],
      // 三类区
      series3: [],
      tipTitle: ''
    }
  },
  components: {
    graphTitle
  },
  watch: {
    tableData: {
      handler (value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1
          this.nameList.push(value[i].dataDate)
          this.dataList.push({ name: value[i].name, value: Number((value[i].value) / 100).toFixed(2) })
        }
        this.totalNum = (this.totalNum / 100).toFixed(2)
        this.drawGraph();
      },
      deep: true
    },
    arrearageSummationtableData: {
      handler (dataObj) {
        //      this.totalNum = 0;
        this.dateList = [];
        let dateArr = [];
        this.series = [];
        this.series1 = [];
        this.series2 = [];
        this.series3 = [];
        Object.keys(dataObj).forEach(key => {
          var value = dataObj[key];
          for (var i = 0; i < value.length; i++) {
            dateArr.push(value[i].dateStr)
            if (value[i].scopeId === '0') {
              this.series.push(Number(value[i].debtMoney / 100).toFixed(2));
            } else if (value[i].scopeId === '1') {
              this.series1.push(Number(value[i].debtMoney / 100).toFixed(2));
            } else if (value[i].scopeId === '2') {
              this.series2.push(Number(value[i].debtMoney / 100).toFixed(2));
            } else if (value[i].scopeId === '3') {
              this.series3.push(Number(value[i].debtMoney / 100).toFixed(2));
            }
          }
        });
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true
    }
  },
  methods: {
    // 去重
    uniq (array) {
      var temp = []
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph () {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById('circularChart'));
      let option = {
        title: {
          text: that.tipTitle
        },
        legend: {
          data: ['全部', '一类区', '二类区', '三类区']
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
              let indexArr = [];
              let count = 0
              params.map(item => {
                indexArr.push(item.componentIndex)
                count += item.value * 1
              })
              let nowIndex = params[0].dataIndex;
				  let countPrice = count.toFixed(2);
				  countPrice = countPrice.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + countPrice.split(".")[1];
          
				  let series1Price = this.series1[nowIndex];
          if (series1Price) {
              series1Price = series1Price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + series1Price.split(".")[1];
          }
				  let series2Price = this.series2[nowIndex];
          if (series2Price) {
             series2Price = series2Price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + series2Price.split(".")[1];
          }
				  let series3Price = this.series3[nowIndex];
          if (series3Price) {
             series3Price = series3Price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + series3Price.split(".")[1];	
          }
				  // series3Price = series3Price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + series3Price.split(".")[1];				  
              let str = 
              `
                <p>
                  ${this.dateList[nowIndex]}
								</p>
                  <p>
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:red;"></span>
                    全部区欠费金额：${countPrice} 元
                <p>
                	</p>
                ${indexArr.includes(0) ? `
                  	<p>
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:violet;"></span>
                      一类区欠费金额：${series1Price} 元
                    </p>
                ` : ''}
                <p>
                	</p>
                ${indexArr.includes(1) ? `
                  	<p>
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#e6a23c;"></span>
                      二类区欠费金额：${series2Price} 元
                    </p>
                ` : ''}
							
                <p>
                	</p>
                ${indexArr.includes(2) ? `
                  	<p>
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#48D1CC;"></span>
                      三类区欠费金额：${series3Price} 元
                    </p>
                ` : ''}
							
                <p>
              `
              return str;
            }
        },
        toolbox: {
          show: true
          //		        feature: {
          //		            magicType: {show: true, type: ['stack', 'tiled']},
          //		            saveAsImage: {show: true}
          //		        }
        },
        // grid: {
        //   width: 800
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.dateList
        },
        yAxis: {
          type: 'value',
          name: '欠费金额(元)'
        },
        series: [
          // {
          //   name: '全部',
          //   type: 'line',
          //   data: that.series,
          //   lineStyle: {
          //     color: 'red'
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: 'red'
          //     }
          //   }
          // },
          {
            name: '一类区',
            type: 'line',
            data: that.series1,
            stack: 'Total',
            lineStyle: {
              color: 'violet'
            },
            itemStyle: {
              normal: {
                color: 'violet'
              }
            }
          },
          {
            name: '二类区',
            type: 'line',
            data: that.series2,
            stack: 'Total',
            lineStyle: {
              color: '#e6a23c'
            },
            itemStyle: {
              normal: {
                color: '#e6a23c'
              }
            }
          },
          {
            name: '三类区',
            type: 'line',
            data: that.series3,
            stack: 'Total',
            lineStyle: {
              color: '#48D1CC'
            },
            itemStyle: {
              normal: {
                color: '#48D1CC'
              }
            }
          }
        ]
      };
      circularChart.setOption(option, true);
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {

  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width 100%
  height 300px
  overflow hidden
</style>
